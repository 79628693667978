import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout/Layout';
import Section from '../components/Section/Section';


const Index: React.FC<PageProps> = () => (
  <Layout>
    <Helmet>
      <title>xAPI - IEEE SA Open</title>
    </Helmet>
    <Section type={'light'}>
    <h1>IEEE SA Open - xAPI</h1> 
    <h2>Experience API (xAPI)</h2>
      
      <p>The Experience API (xAPI) is a standard that describes an
      interoperable means to document and communicate information
      about learning experiences. It specifies a structure to describe
      learning experiences and defines how these descriptions can be
      exchanged electronically.  </p>

      <p>The xAPI Base Draft Standard is an IEEE Open Source Project
      hosted on the IEEE Open Source Platform (it is licensed under
      the terms of the Apache 2.0 License and copyright the IEEE XAPI
      Authors see the <a
      href="https://opensource.ieee.org/xapi/about">xAPI About open
      source project</a> for licensing and copyright information as
      well as additional information on contributing to this open
      source project). See also the <a
      href="https://opensource.ieee.org/xapi/xapi-base-standard-documentation">xAPI
      Base Draft Standard</a> and <a
      href="https://opensource.ieee.org/xapi/xapi-base-standard-examples">xAPI
      Examples</a> open source projects.
      </p>

      <p><strong>NOTICE: </strong>This open source repository contains
      material that may be included-in or referenced by an unapproved
      draft of a proposed IEEE Standard. All material in this
      repository is subject to change. The material in this repository
      is presented "as is" and with all faults. Use of the material is
      at the sole risk of the user. IEEE specifically disclaims all
      warranties and representations with respect to all material
      contained in this repository and shall not be liable, under any
      theory, for any use of the material. Unapproved drafts of
      proposed IEEE standards must not be utilized for any
      conformance/compliance purposes.</p>

    </Section>
  </Layout>
);

export default Index;
